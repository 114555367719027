import React from "react"

const IndexPage = () => (
  <div>
    <p>
      <br />
      <br />
      • Survicate Sp. z o.o., con número de identificación Fiscal (NIP) No.
      9512390641, y domicilio en Zamiany 8 LU2, Warsaw, Polonia.
      <br />
      • Crazyegg Inc., con domicilio en 16220 E. Ridgeview Lane, La Mirada, CA
      90638
      <br />
      • Hubspot Inc. con domicilio en 25 1st Street Cambridge, MA 02141 United
      States
      <br />• LinkedIn Inc., con domicilio en 1000 West Maude Avenue Sunnyvale,
      CA 94085 USA
    </p>
  </div>
)

export default IndexPage
